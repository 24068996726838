// // 通过id表在树中查询元素并返回最次级id表

function queryList(json, arr) {
    for (var i = 0; i < json.length; i++) {
        var children = json[i].children;
        if (children.length == 0) {
            arr.push(json[i].id);
        } else {
            queryList(children, arr);
        }
    }
    return arr;
}
export function getUnderIdsByIdsInTree(tree,ids){
    let lastArr = [];
    let lastIdList = []
    queryList(tree, lastArr)
    ids.forEach(id => {
        if(lastArr.includes(id)){
            lastIdList.push(id)
        }
    })
    return lastIdList
}