// 菜单管理
import { Request } from '@/http/request'

// 菜单树获取
export function tree() {
  return Request.axiosInstance.post('/system/menu/tree')
}
// 添加菜单
export function addTree(parameter: any) {
  return Request.axiosInstance.post('/system/menu/add', parameter)
}
// 删除菜单 
export function deleteTree(parameter: any) {
  return Request.axiosInstance.post('/system/menu/delete', parameter)
}
// 菜单查询
export function getTreeData(parameter: any) {
  return Request.axiosInstance.post('/system/menu/get', parameter)
}
// 菜单修改
export function editTreeData(parameter: any) {
  return Request.axiosInstance.post('/system/menu/edit', parameter)
}

export default { tree, addTree, deleteTree, getTreeData, editTreeData }