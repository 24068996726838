// 角色管理
<template>
    <div class="jobs" v-if="centerDialogVisible == false">
        <div class="screening">
            <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="筛选框" name="1">
                    <div class="screening-out">
                        <el-form :inline="true" :model="searchFrom" class="demo-form-inline">
                            <el-row>
                                <el-col :span="22">
                                    <el-form-item label="角色名称">
                                        <el-input v-model="searchFrom.name" placeholder="请输入需要查询的角色名称" size="mini"></el-input>
                                    </el-form-item>
                                    <!-- <el-form-item label="所属部门">
                                        <el-input v-model="searchFrom.name" placeholder="请输入需要查询的部门名称" size="mini"></el-input>
                                    </el-form-item> -->
                                    <!-- <el-form-item label="创建时间">
                                        <el-date-picker
                                            v-model="valueTime"
                                            type="datetimerange"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            clearable
                                            value-format="YYYY-MM-DD HH:mm:ss"
                                            size="mini"
                                            :default-time="defaultTime">
                                        </el-date-picker>
                                    </el-form-item> -->
                                    <el-form-item label="状态：" prop="">
                                        <el-select
                                        v-model="statusName"
                                        placeholder="请选择"
                                        style="width: 100%"
                                        clearable
                                        size="mini"
                                        >
                                        <el-option
                                            v-for="(item, index) in ['启用', '禁用']"
                                            :key="index"
                                            :label="item"
                                            :value="index"
                                        >
                                        </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2">
                                    <!-- <el-form-item> -->
                                        <div class="title-buttom">
                                            <el-button class="cancel" size="mini" @click="reset" round>重置</el-button>
                                            <el-button type="primary" size="mini" class="confirmAdd" @click="getDataList(true)" round>查询</el-button>
                                        </div>
                                    <!-- </el-form-item> -->
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <el-row>
            <el-col :span="12">
                <div class="title-name">
                    角色列表
                </div>
            </el-col>
            <el-col :span="12">
                <div class="title-buttom">
                    <el-tooltip content="新增角色" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-plus" @click="addData" circle></el-button>
                    </el-tooltip>
                    <el-tooltip content="刷新列表" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-refresh-right" @click="reset" circle></el-button>
                    </el-tooltip>
                    <!-- <el-button class="deletetable" size="mini" icon="el-icon-delete" @click="deleteData" round>删除</el-button> -->
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div>
                    <el-table
                    v-loading="loading"
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :max-height="tableHeight"
                    :header-cell-style="{background:'#e0e5ff',color:'#24252F'}"
                    @selection-change="handleSelectionChange">
                        <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
                        <el-table-column
                        label="角色名称">
                        <template #default="scope">{{ scope.row.name == null?'-': scope.row.name}}</template>
                        </el-table-column>
                        <el-table-column
                        label="所属部门">
                        <template #default="scope">{{ scope.row.department == null?'-': scope.row.department}}</template>
                        </el-table-column>
                        <el-table-column
                        label="创建时间">
                        <template #default="scope">{{ scope.row.createTime == null?'-': scope.row.createTime}}</template>
                        </el-table-column>
                        <!-- <el-table-column
                        min-width="200" 
                        :show-overflow-tooltip="true"
                        label="备注">
                        <template #default="scope">{{ scope.row.remark == null?'-': scope.row.remark}}</template>
                        </el-table-column> -->
                        <el-table-column
                        label="启用/禁用" align="center">
                        <!-- <template #default="scope">
                            <el-popconfirm
                            confirmButtonText="确定"
                            cancelButtonText="取消"
                            icon="el-icon-info"
                            iconColor="red"
                            :title="scope.row.status == 0?'确定禁用这个职位吗？':'确定启用这个职位吗？'"
                            @confirm="control(scope.row.id,scope.row.status)"
                            @cancel="cancelEvent">
                                <template #reference>
                                    <el-button :class="scope.row.status == 0?'deletetable':'editor'" size="mini" :icon="scope.row.status == 0?'el-icon-close':'el-icon-check'" round></el-button>
                                </template>
                            </el-popconfirm>
                        </template> -->
                        <template #default="scope">
                            <a-switch 
                                size="small" 
                                v-model:checked="scope.row.status" 
                                :checkedValue="0"
                                :unCheckedValue="1"
                                @click="onSwitch(scope.row, scope.row.status)" 
                            />
                            <!-- <div style="height:25px;" @click="onSwitch(scope.row, scope.row.status)">
                                <el-switch
                                style="display: block"
                                v-model="scope.row.status"
                                :active-value = '0'
                                :inactive-value = '1'
                                active-color="#637DFF"
                                inactive-color="#ff6e3e">
                                </el-switch>
                            </div> -->
                        </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template #default="scope">
                                <el-tooltip content="编辑角色" placement="top" effect="light">
                                    <el-button class="editor" size="mini" icon="el-icon-edit" @click="modify(scope.row)" round></el-button>
                                </el-tooltip>
                                <el-tooltip content="删除角色" placement="top" effect="light">
                                    <el-button class="deletetable" size="mini" icon="el-icon-delete" @click="confirmEvent(scope.row.id)" round></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <div class="pager">
            <div>
               <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                background
                :page-sizes="[10, 20, 30, 40]"
                :page-size="searchFrom.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
    <div class="jobs" v-else>
        <div class="other-title-top">
            <el-page-header icon="el-icon-arrow-left" content="角色管理" @click="backOut"></el-page-header>
            <div class="name-top">
                {{ popupTitle==0? '新增角色':'修改角色'}}
            </div>
            <div></div>
        </div>
        <el-form label-position="top" label-width="100px" :model="upform" :rules="rules" ref="upform">
            <el-row>
                <el-col :span="8">
                    <div class="form-ove">
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="角色名称：" prop="name">
                                    <el-input placeholder="请输入内容" v-model="upform.name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="拥有权限："  prop="rules">
                                    <el-input
                                    placeholder="请在右侧勾选权限"
                                    v-model="checkTheRule"
                                    :disabled="true">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="可查看菜单："  prop="rules">
                                    <el-input
                                    placeholder="请在右侧勾选菜单"
                                    v-model="checkTheMenu"
                                    :disabled="true">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item label="角色备注：">
                                    <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    :autosize="{ minRows: 4, maxRows: 6}"
                                    v-model="upform.remark"
                                    maxlength="30"
                                    show-word-limit
                                    >
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item>
                            <div class="space-tile form-ove">
                                <el-button class="cancel" plain @click="resetForm('upform')" round>取消</el-button>
                                <el-button class="confirmAdd" @click="submitForm('upform')" round>{{ popupTitle == 0 ? '新增角色' : '修改角色' }}</el-button>
                                <!-- <el-button @click="getCheckedNodes">通过 node 获取</el-button> -->
                            </div>
                        </el-form-item>
                    </div>
                </el-col>
            <el-col :span="16">
                <div class="out-show-tree">
                    <el-row>
                        <el-col :span="4">
                            <el-affix :offset="185">
                                <div class="margin-bottom">
                                    <el-radio-group v-model="species" size="small" fill="#637DFF">
                                        <el-radio-button label="权限"></el-radio-button>
                                        <el-radio-button label="菜单"></el-radio-button>
                                    </el-radio-group>
                                </div>
                                <div class="margin-bottom">
                                    <el-input v-model="filterText"></el-input>
                                </div>
                                <div class="margin-bottom">
                                    <el-button class="cancel" round @click="selectAll">全选</el-button>
                                </div>
                                <div class="margin-bottom">
                                    <el-button class="cancel" round @click="InvertSelection">反选</el-button>
                                </div>
                            </el-affix>
                        </el-col>
                        <el-col :span="2"></el-col>
                        <el-col :span="9">
                            <el-affix :offset="185">
                                <div class="tree-name">权限树图：</div>
                            </el-affix>
                            <div class="show-tree">
                                <el-tree
                                :data="ruleList"
                                show-checkbox
                                node-key="id"
                                ref="ruleTree"
                                :filter-node-method="filterNodeRule"
                                default-expand-all
                                @check="isNodeRuleClick"
                                :default-checked-keys="ruleClickList"
                                :props="defaultProps">
                                <template #default="{ data }">
                                <span class="custom-tree-node">
                                    <span>{{ data.name }}</span>
                                    <span :class="'appName'+data.appId">{{ $filter.appIdToModule(data.appId,ppJson) }}</span>
                                </span>
                                </template>
                                </el-tree>
                            </div>
                        </el-col>
                        <el-col :span="9">
                            <el-affix :offset="185">
                                <div class="tree-name">菜单树图：</div>
                            </el-affix>
                            <div class="show-tree">
                                <el-tree
                                :data="menuList"
                                show-checkbox
                                node-key="id"
                                ref="menuTree"
                                :filter-node-method="filterNodeMenu"
                                default-expand-all
                                @check="isNodeMenuClick"
                                :default-checked-keys="menuClickList"
                                :props="defaultProps">
                                <template #default="{ data }">
                                <span class="custom-tree-node">
                                    <span>{{ data.name }}</span>
                                    <span :class="'appName'+data.appId">{{ $filter.appIdToModule(data.appId,ppJson) }}</span>
                                </span>
                                </template>
                                </el-tree>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
        </el-form>
    </div>
</template>
<script>
import menu from '@/api/system/menu'
import role from '@/api/system/role'
import app from '@/api/web/app'
import rule from '@/api/system/rule'
import { IconFont } from "@/utils/iconfont"
import { getUnderIdsByIdsInTree } from '@/assets/js/common'
export default {
    components: {
        IconFont,
    },
    data () {
        return {
            tableHeight:'',
            species:'权限',//功能框区分
            checkTheRule:'',//已勾选权限数
            checkTheMenu:'',//已勾选菜单数
            filterText:'',//树形组件关键字搜索
            ruleList:[],//权限树
            menuList:[],//菜单树
            ruleClickList:[],//选中权限节点
            menuClickList:[],//选中菜单节点
            searchFrom:{
                page:1,
                pageSize:10,
                name:'',
                status:'',
                createTimeBegin:'',
                createTimeEnd:'',
            },//搜索值
            statusName: 0,
            currentPage: 1,//分页
            total:0,
            multipleSelection: [],//选中集合
            value:'',//所属部门
            valueTime:'',//选中时间段
            defaultTime: [
                new Date(2000, 1, 1, 12, 0, 0),
                new Date(2000, 2, 1, 8, 0, 0)
            ], //默认时间 '12:00:00', '08:00:00'
            activeNames: [],//折叠面板
            tableData: [],//数据列表
            loading: true,// loding 状态启用
            popupTitle:0,//弹窗类别
            centerDialogVisible: false,//弹窗启用
            innerVisible: false,//图标弹窗启用
            upform: {//角色信息
                name: '',//角色名称
                rules:'',//权限
                menus:'',//菜单
                remark: '',//角色备注
            },
            rules: {
                name: [
                    { required: true, message: '请输入角色名称', trigger: 'blur' },
                    { min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur' }
                ],
                rules: [
                    { required: true, message: '请选择角色拥有权限', trigger: 'blur' },
                ],
            },
            defaultProps: {
                children: 'children',
                label: 'name'
            }
        }
    },
    created() {
        this.tableHeight = this.$store.state.tableHeight
    },
    watch: {
        // 根据关键词过滤
        filterText(val) {
            if(this.species == '权限'){
                this.$refs.ruleTree.filter(val);
            }else{
                this.$refs.menuTree.filter(val);
            }
        }
    },
    mounted() {
        this.getDataList(true)
        this.getLowList()
        this.getRuleTree()
    },
    methods: {
        getLowList(){
            app.appQuery({}).then((res) => {
                this.options = res.data
                let newJson = {}
                res.data.forEach(item => {
                    newJson[item.id] = item.name
                });
                this.ppJson = newJson
            })
        },
        // 重置
        reset() {
            this.valueTime = []
            this.statusName = 0
            this.searchFrom = {
                page: this.currentPage,
                pageSize: 10, 
            }
            this.getDataList(true)
        },
        // 获取权限树 获取菜单树
        getRuleTree(){
            rule.getRuleTree({}).then((res) => {
                this.ruleList = res.data
            })
            menu.tree({}).then((res) => {
                this.menuList = res.data
            })
        },
        // 获取角色列表
        getDataList(type) {
            this.loading = type
            this.searchFrom.createTimeBegin = this.valueTime[0]
            this.searchFrom.createTimeEnd = this.valueTime[1]
            // this.statusName == '启用'?this.searchFrom.status = '0':this.searchFrom.status = '1'
            this.searchFrom.status = this.statusName
            role.getRoleTree({
                ...this.searchFrom
            }).then((res) => {
                this.tableData = res.data.records
                this.total = res.data.total
                this.loading = false
            })
        },
        // 分页
        handleSizeChange(val) {
            this.searchFrom.pageSize = val
            this.getDataList(true)
        },
        handleCurrentChange(val) {
            this.searchFrom.page = val
            this.currentPage = val
            this.getDataList(true)
        },
        // 折叠面板
        handleChange(val) {
            console.log(val);
        },
        // 多选
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 批量删除
        deleteData() {
            console.log(this.multipleSelection)
        },
        // 返回前一页
        backOut(){
            this.popupTitle = 0
            this.centerDialogVisible = false
        },
        // 新增
        addData(){
            this.upform = {}
            this.checkTheRule = ''
            this.checkTheMenu = ''
            this.ruleClickList = []
            this.menuClickList = []
            this.popupTitle = 0
            this.centerDialogVisible = true
        },
        // 控制状态
        onSwitch(row, status){
            role.editRole({
                id:row.id,
                status: status == 0 ? 0 : 1
            }).then((res) => {
                this.$notify({
                    title: '成功',
                    message: status == 0 ? '该角色启用成功！' : '该角色禁用成功！',
                    type: 'success'
                });
                this.getDataList(false)
                this.centerDialogVisible = false
            })
        },
        // 修改
        modify(row) {
            this.upform = row
            if(row.rules != null){
                // console.log()
                this.ruleClickList = getUnderIdsByIdsInTree(this.ruleList,row.rules.split(","))
                this.ruleClickList.length == 0? this.checkTheRule = '' : this.checkTheRule = '已拥有'+this.ruleClickList.length+'条权限'
            }
            if(row.menus != null){
                // this.menuClickList = row.menus.split(",")
                this.menuClickList = getUnderIdsByIdsInTree(this.menuList,row.menus.split(","))
                this.menuClickList.length == 0? this.checkTheMenu = '' : this.checkTheMenu = '可查看'+this.menuClickList.length+'条菜单'
            }
            this.popupTitle = 1
            this.centerDialogVisible = true
        },
        // 删除确认
        confirmEvent(id) {
            this.$confirm('将删除该角色, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                role.deleteRole({
                    id:id
                }).then((res) => {
                    this.$notify({
                        title: '成功',
                        message: '角色删除成功！',
                        type: 'success'
                    });
                    this.getDataList(true)
                })
            }).catch(() => {
                this.$notify.info({
                    title: '通知',
                    message: '已取消删除!'
                });
            });
        },
        // 取消
        cancelEvent() {
            console.log("cancel!");
        },
        // 表单提交
        submitForm(formName) {
            this.upform.departmentId = this.value[this.value.length-1]
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if( this.popupTitle == 0 ){
                        role.addRole({
                            ...this.upform
                        }).then((res) => {
                            this.$notify({
                                title: '成功',
                                message: '角色添加成功！',
                                type: 'success'
                            });
                            this.getDataList(true)
                            this.centerDialogVisible = false
                        })
                    }else{
                        role.editRole({
                            ...this.upform
                        }).then((res) => {
                            this.$notify({
                                title: '成功',
                                message: '角色修改成功！',
                                type: 'success'
                            });
                            this.getDataList(true)
                            this.centerDialogVisible = false
                        })
                    }
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.value = []
            this.centerDialogVisible = false
        },
        closeDialog() {
            this.$refs.upform.resetFields()
            this.value = []
            this.centerDialogVisible = false
        },
        // 树形 =======================================================
        // 全选
        selectAll(){
            alert(this.species);
            if(this.species == '权限'){
                this.$refs.ruleTree.setCheckedNodes(this.ruleList)
                this.disposeList(this.$refs.ruleTree.getCheckedNodes())
            }else{
                this.$refs.menuTree.setCheckedNodes(this.menuList)
                this.disposeList(this.$refs.menuTree.getCheckedNodes())
            }  
        },
        // 反选
        InvertSelection(){
            if(this.species == '权限'){
                let res = this.$refs.ruleTree;
                let nodes = res.getCheckedNodes(true, true);
                this.batchSelect(this.ruleList, res, true, nodes);
                this.disposeList(this.$refs.ruleTree.getCheckedNodes())  
            }else{
                let res = this.$refs.menuTree;
                let nodes = res.getCheckedNodes(true, true);
                this.batchSelect(this.menuList, res, true, nodes);
                this.disposeList(this.$refs.menuTree.getCheckedNodes())  
            }
        },
        // 勾选
        isNodeRuleClick(data, node, obj) {
            this.disposeList(this.$refs.ruleTree.getCheckedNodes(),0)  
        },
        isNodeMenuClick(){
            this.disposeList(this.$refs.menuTree.getCheckedNodes(),1)  
        },
        // 获取选中的父级节点
        getParent(formName){
            let parArr = this.$refs[formName].getHalfCheckedNodes();
            let parOther = [];
            parArr.forEach((item,i) =>{
                parOther.push(item.id)
            })
            return parOther
        },
        // 处理勾选列表
        disposeList(list,type){
            let newClick = []
            list.forEach((node,n)=>{
                newClick.push(node.id) 
            })
            if(type == 0){
                let parList = this.getParent('ruleTree')
                let allList = newClick.concat(parList)
                newClick.length == 0? this.checkTheRule = '' : this.checkTheRule = '已拥有'+newClick.length+'条权限'
                this.upform.rules = allList.toString()
            }else{
                let parList = this.getParent('menuTree')
                let allList = newClick.concat(parList)
                newClick.length == 0? this.checkTheMenu = '' : this.checkTheMenu = '可查看'+newClick.length+'条菜单'
                this.upform.menus = allList.toString()
            }
        },
        // 全选处理方法
        batchSelect(nodes, refs, flag, seleteds) {
          if (typeof nodes != "undefined") {
            nodes.forEach(element => {
              refs.setChecked(element, flag, true);
            });
          }

          if (typeof seleteds != "undefined") {
            seleteds.forEach(node => {
              refs.setChecked(node, !flag, true);
            });
          }
        },
        filterNodeMenu(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        filterNodeRule(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        // 判断是否为最下级
    }
}
</script>

<style lang="scss" scoped>
.pager {
    display: flex;
    justify-content: flex-end;
}
.jobs{
    height: 100%;
    overflow-y: overlay;
    padding-right: 18px;
    .title-name{
        font-size: .9375rem;
        font-weight: 600;
        color: #303133;
    }
    .top-first{
        .search-box{
            width: 18.75rem;
        }
    }
}
.screening{
    margin-bottom: .9375rem;
}
.el-row {
    margin-bottom: .9375rem;
    &:last-child {
        margin-bottom: 0;
    }
}
.custom-tree-node{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
.appName0{
    color: #409EFF;
}
.appName1{
    color: #67C23A;
}
.appName2{
    color: #E6A23C;
}
.appName3{
    color: #F56C6C;
}
.space-tile{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.editor{
    color: #0170ED;
    background: #E3F0FF;
}
.center-body{
    margin: 0 1.875rem;
}
.deletetable{
    color: #FF845B;
    background: #FFE4DB;
}
.deletetable-other{
    margin-right: 1.25rem;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.cancel{
    color: #637DFF;
    border: #637DFF solid 1px;
}
.screening-out{
    margin: 0 .9375rem;
}
.showIcon{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    overflow-y: overlay;
    height: 28.125rem;
    .out-icon{
        border-radius: .5rem;
        .icon-box{
            display: flex;
            justify-content: space-between;
            align-items: center;
            justify-content: center;
            width:  4rem;
            height: 3rem;
        }
        .icon-name{
            text-align: center;
            width: 4rem;
            height: 2.5rem;
            line-height: 2.5rem;
            
        }
    } 
}
.out-icon:hover{
    background: #E3F0FF;
    color: #0170ED;
}

// other
.other-title-top{
    height: 3.125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.25rem;
    background: #e0e5ff;
    .name-top{
        font-size: .875rem;
        font-weight: 600;
    }
}
.form-ove{
    padding: 0 1.875rem;
}
.out-show-tree{
    padding: 1rem;
    margin-top: 1.25rem;
    border-radius: 1em;
    box-shadow: 0px 0px 6px 0px #d3d6e2; 
}
.show-tree{
    height: calc(100vh - 290px);
    overflow-y: overlay;
}
.margin-bottom{
    margin-bottom: 1rem;
}
.tree-name{
    font-size: .875rem;
    height: 3.125rem;
    // line-height: 60px;
}
</style>

<style lang="scss">
.jobs .el-collapse-item__content {
    padding-bottom: 0 !important;
}
// .jobs .el-button {
//     // border-color: #FFFFFF !important;
// }
</style>